/* .modal-dialog {
  top: 5%;
} */

div.modal-content {
  background-color: hsla(219, 28%, 16%, 1);
}

.fade.modal-backdrop.show {
  height: 100%;
}
