#nav a.active {
  font-weight: 800;
  /* text-decoration: underline; */
  /* border-bottom-width: 3px;
  border-bottom-style: solid; */
  color: hsl(207, 90%, 72%);
  background-color: #0b111e;
  position: relative;
  z-index: 1;
  text-align: center;
}
@media only screen and (min-width: 992px) {
  #nav a.active::after {
    content: "";
    position: absolute;
    margin-left: -52%;
    text-align: center;
    /* left: 50%; */
    bottom: 0;
    width: 1.25rem;
    border-bottom: 3px solid hsl(207, 90%, 72%);
  }
}
/* #basic-nav-dropdown {
  color: hsl(207, 90%, 72%);
}

#nav a {
  color: hsl(219, 8%, 75%);
} */
#nav-link {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-weight: 500;
}

#pro.dropdown-toggle::after {
  display: none;
}
