.social {
  font-size: 1.5rem;
  width: max-content;
  background-color: hsl(219, 32%, 12%);
  color: hsl(207, 90%, 72%);
  padding: 0.25rem;
  border-radius: 0.25rem;
  display: flex;
  transition: 0.4s;
  cursor: pointer;
}

.social-container::after {
  content: "";
  width: 32px;
  height: 2px;
  background-color: hsl(207, 90%, 72%);
  transform: rotate(90deg) translate(16px, 3px);
  margin-right: auto;
  margin-left: auto;
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
}
